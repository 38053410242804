import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Heading from "react-bulma-components/lib/components/heading/heading";
import { cn } from "reusable-components/helper";

import Styles from "./index.module.scss";


const Logo = ({ className, ...props }) =>
	<a
		href={ "/" }
		aria-label={ "Logo" }
		className={ [
			"icon-logo",
			Styles.logo,
			className
		].join(" ") }
		{ ...props }
	/>;

const LogoFull = ({ className, ...props }) => {
	const { takeshape } = useStaticQuery(graphql`
		query {
			takeshape {
				getGeneralData {
					companyName
					companySubtitle
				}
			}
		}
	`);

	return (
		<div
			className={ cn(Styles.fullLogo, className) }
			{ ...props }>

			<Logo />

			<div className={ Styles.text }>
				<Heading
					className={ Styles.companyName }
					textSize={ 6 }
					renderAs={ "h1" }>
					{ takeshape.getGeneralData.companyName }
				</Heading>

				<Heading
					subtitle
					className={ Styles.companyDesc }
					textSize={ 6 }
					renderAs={ "h2" }>
					{ takeshape.getGeneralData.companySubtitle }
				</Heading>
			</div>
		</div>
	)
};

export { LogoFull };

export default Logo;

