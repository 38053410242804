import React  from "react";

import Styles from "./index.module.scss";


const Author = () =>
	<section className={ Styles.authorSection }>
		Made with ❤ by <br/>
		<a
			href="https://www.coderwelsch.com"
			target="_blank"
			rel="noopener noreferrer"
			className={ Styles.link }>
			Coderwelsch
		</a>
	</section>;

export default Author;